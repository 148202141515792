<template>
	<div class="content">
		<div class="make">
			<div class="text">
				<h3>{{ $t('lang.SJDL_h3') }}</h3>
				<table border="1">
					<tr>
						<td>{{ $t('lang.SJDL_table1') }}</td>
						<td>{{ $t('lang.SJDL_table2') }}</td>
						<td>{{ $t('lang.SJDL_table3') }}</td>
						<td>{{ $t('lang.SJDL_table4') }}</td>
						<td>{{ $t('lang.SJDL_table5') }}</td>
					</tr>
					<tr>
						<td>{{ $t('lang.SJDL_table6') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table7') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table8') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table9') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table10') }}</td>
					</tr>
					<tr>
						<td>{{ $t('lang.SJDL_table11') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table12') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table13') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table14') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table15') }}</td>
					</tr>
					<tr>
						<td>{{ $t('lang.SJDL_table16') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table17') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table18') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table19') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table20') }}</td>
					</tr>
					<tr>
						<td>{{ $t('lang.SJDL_table21') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table22') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table23') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table24') }}</td>
						<td style="color: #fff;">{{ $t('lang.SJDL_table25') }}</td>
					</tr>
					<tr>
						<td rowspan="2">{{ $t('lang.SJDL_table26') }}</td>
						<td>{{ $t('lang.SJDL_table27') }}</td>
						<td colspan="3">{{ $t('lang.SJDL_table28') }}</td>
					</tr>
					<tr>
						<td>{{ $t('lang.SJDL_table29') }}</td>
						<td colspan="3">{{ $t('lang.SJDL_table30') }}</td>
					</tr>
				</table>
			</div>
			<!-- <div class="text-vip">
				<h3>{{ $t('lang.vip_h3') }}</h3>
				<table border="1">
					<tr>
						<td>{{ $t('lang.vip_table1') }}</td>
						<td>{{ $t('lang.vip_table2') }}</td>
						<td>{{ $t('lang.vip_table3') }}</td>
						<td>{{ $t('lang.vip_table4') }}</td>
					</tr>
					<tr>
						<td style="color: #fff;">{{ $t('lang.vip_table5') }}</td>
						<td style="color: #fff;">{{ $t('lang.vip_table6') }}</td>
						<td style="color: #fff;">{{ $t('lang.vip_table7') }}</td>
						<td style="color: #fff;">{{ $t('lang.vip_table8') }}</td>
					</tr>
				</table>
			</div>
			<div class="text-p">
				<p>{{ $t('lang.vip_p1') }}</p>
				<p>{{ $t('lang.vip_p2') }}</p>
				<p>{{ $t('lang.vip_p3') }}</p>
			</div> -->
		</div>
	</div>
</template>

<script>
	import Header from "@/components/Header";
	import HB from "@/assets/js/bridge";
	export default {
		components: {
			Header
		},
		data() {
			return {
				language: '',
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			localStorage.setItem('lang', this.language);
			this.$i18n.locale = localStorage.getItem("lang");
		},
		mounted() {
			
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped>
	// * {
	// 	font-family: PingFang;
	// }
	.content {
		width: 100%;
		background-color: #00246C;
	}
	.make {
		width: 92%;
		margin: 0 auto;
		border-radius: 27px;
	}
	.text {
		padding-bottom: 1rem;
		h3 {
			font-size: 0.32rem;
			font-weight: 600;
			line-height: 0.64rem;
			color: #FFFFFF;
		}
		table {
			width: 100%;
			font-size: 0.28rem;
			font-weight: 600;
			color: #8CA7E2;
			border: 1px solid #8CA7E2;
			border-radius: 8px;
			margin-top: 0.1rem;
			border-collapse: collapse;
			border-spacing: 0;
			/* 消除掉外边框 */
			border-style: hidden;
			/* hack一下加个阴影 */
			box-shadow: 0 0 0 1px #8CA7E2;
			tr {
				height: 0.8rem;
				td {
					width: 1.38rem;
					text-align: center;
					padding: 0.2rem 0;
				}
			}
		}
	}
	.text-vip {
		h3 {
			font-size: 0.32rem;
			font-weight: 600;
			line-height: 0.64rem;
			color: #FFFFFF;
			margin-top: 0.2rem;
		}
		table {
			width: 100%;
			font-size: 0.28rem;
			font-weight: 600;
			color: #8CA7E2;
			border: 1px solid #8CA7E2;
			border-radius: 8px;
			margin-top: 0.1rem;
			border-collapse: collapse;
			border-spacing: 0;
			/* 消除掉外边框 */
			border-style: hidden;
			/* hack一下加个阴影 */
			box-shadow: 0 0 0 1px #8CA7E2;
			tr {
				height: 0.8rem;
				td {
					width: 1.38rem;
					text-align: center;
					padding: 0.2rem 0;
				}
			}
		}
	}
	.text-p {
		padding-top: 0.2rem;
		padding-bottom: 1.6rem;
		p {
			font-size: 0.22rem;
			font-weight: 400;
			color: #8CA7E2;
			margin-top: 0.08rem;
		}
	}
</style>